<template>
  <div class="search-containter">
    <nav-menu></nav-menu>
    <div class="search-body">
      <div class="containter">
        <el-carousel>
          <el-carousel-item>
            <img src="@/assets/banner.png" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img src="@/assets/banner1.png" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img src="@/assets/banner2.png" alt="">
          </el-carousel-item>
        </el-carousel>
        <div class="title">
          电子保函查验真伪
        </div>
        <div class="search">
          <el-input placeholder="请输入要查询的保函编号" v-model="keyword" class="input-with-select" @keyup.enter.native="search">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </div>
        <div class="result">
          <div class="result-file" v-if="info.gatUrl">
            <div class="result-title">{{ info.tendName }}</div>
            <div class="result-file-title flex-a flex-b">
              <p>标段编号：{{ info.tendNo }}</p>
              <p>保证金金额：￥{{ info.prjEarMoney }}</p>
            </div>
            <div class="result-file-title flex-a flex-b">
              <p>开标时间：{{ info.tendStDate }}</p>
              <p>保证金缴纳截至时间：{{ info.prjEndEarDate }}</p>
            </div>
            <div class="result-file-title flex-a flex-b">
              <p>保函类型：{{ info.wayCode }}</p>
              <p>申请时间：{{ info.createdAt }}</p>
            </div>
            <div class="result-file-title flex-a flex-b">
              <p>申请公司名称：{{ info.coName }}</p>
            </div>
            <div class="result-file-content flex">
              <img src="@/assets/pdf.svg" alt="">
              <el-tag type="danger" size="small">加密</el-tag>
              <span class="result-file-content-title">{{ info.gatNo }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import { getSearchByToken, getSearch } from './../lib/api/index'
export default {
  name: 'search',
  data() {
    return {
      keyword: '',
      info: {}
    }
  },
  created() {
    if (this.$route.query.id) {
      this.keyword = this.$route.query.id
      this.search()
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    search() {
      if (this.keyword == '') {
        this.$message({
          message: '请输入要查询的保函编号',
          type: 'warning'
        });
      } else {
        this.info = {}
        if (window.localStorage.getItem("token")) {
          this.getSearchByToken(this.keyword)// 真伪查询-登录
        } else {
          this.getSearch(this.keyword)// 真伪查询-未登录
        }
      }
    },
    // 真伪查询-未登录
    getSearch(keyword) {
      getSearch(keyword).then(res => {
        if (res.code == 200) {
          if (!res.data || res.data.type != '1') {
            this.$message({
              message: '暂未查询到该保函！',
              type: 'warning'
            });
          } else {
            this.info = res.data || {}
          }
        }
      })
    },
    // 真伪查询-登录
    getSearchByToken(keyword) {
      getSearchByToken(keyword).then(res => {
        if (res.code == 200) {
          if (!res.data || res.data.type != '1') {
            this.$message({
              message: '暂未查询到该保函！',
              type: 'warning'
            });
          } else {
            this.info = res.data || {}
          }
        }
      })
    },
  },
}
</script>

<style lang="less">
.search-containter {
  background: #F0F2F5;
  min-height: 100vh;

  .search-body {
    padding: 0;

    .containter {
      padding: 20px 20px;
      margin: 20px auto;
      min-height: 72vh;
      box-sizing: border-box;

      .el-carousel__container {
        height: 240px;

        img {
          width: 100%;
        }
      }

      .title {
        color: #181818;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin: 20px 0;
      }

      .search {
        width: 500px;
        margin: 0 auto;
        border: 2px solid #212121;
        border-radius: 5px;

        .el-input__inner {
          border: none;
        }

        .el-button {
          background: #5280FF;
        }

        i {
          color: #fff;
        }
      }
    }
  }
}

.result-file {
  padding: 20px 80px 0 80px;

  .result-title {
    font-weight: bold;
    font-size: 20px;
  }

  .result-file-title {
    color: #5A5A5A;
    font-size: 14px;
    padding: 0 40px;

    p {
      width: 40%;
    }
  }

  .result-file-content {
    box-shadow: 0 0 10px #E8E8E8;
    margin-top: 15px;
    padding: 20px 50px 20px 30px;
    border-radius: 5px;
    position: relative;

    img {
      width: 60px;
    }

    .el-tag {
      margin: 0 10px;
    }

    .result-file-content-title {
      color: #2C3E50;
    }

    .btn-plain {
      border-color: #157EDF;
      color: #1890FF;
      position: absolute;
      bottom: 20px;
      right: 50px;

      a {
        color: #1890FF;
      }
    }

    .preview {
      right: 150px;
    }
  }
}
</style>
